<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <b-card>
          <b-row>
            <b-col sm="4">
              <!-- Parameter -->

              <b-card no-body class="border mt-1">
                <b-card-header class="p-1">
                  <b-card-title class="font-medium-1">
                    <feather-icon icon="GlobeIcon" size="14" />
                    <span class="align-middle ml-50">Kabupaten</span>
                  </b-card-title>
                </b-card-header>
                <b-row class="pl-1 pr-1 mt-1">
                  <b-col>
                    <b-form-group label-cols="4" label-cols-lg="6" label-size="sm" label="Balai" label-for="input-sm">
                      <b-form-select v-model="regions" :options="regions_opt" size="sm" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="pl-1 pr-1">
                  <b-col>
                    <b-form-group label-cols="4" label-cols-lg="6" label-size="sm" label="Provinsi" label-for="input-sm">
                      <div class="icon1" style="margin-left:-105px; position: absolute;">
                        <feather-icon icon="Help-Circle" size="10" class="text-danger" v-b-tooltip.click.v-danger.top title="Radiasi total selama 24 jam yang lalu dalam Joule/cm2, input nilai dalam persepuluhan" />
                      </div>
                      <b-form-select v-model="propinsi" :options="propinsi_opt" size="sm" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="pl-1 pr-1 ">
                  <b-col>
                    <b-form-group label-cols="4" label-cols-lg="6" label-size="sm" label="Nama Kabupaten" label-for="input-sm">
                      <b-form-input id="kabupaten" v-model="kabupaten" size="sm" placeholder="Auto" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
              <b-button v-if="status_entry == 'insert'" variant="primary" @click="submit" class="mb-1 mb-sm-0 mr-0 mr-sm-1" size="sm" :block="$store.getters['app/currentBreakPoint'] === 'xs'">
                Tambah
              </b-button>
              <b-button v-if="status_entry == 'edit'" variant="primary" @click="update" class="mb-1 mb-sm-0 mr-0 mr-sm-1" size="sm" :block="$store.getters['app/currentBreakPoint'] === 'xs'">
                Ubah
              </b-button>
              <b-button variant="gradient-danger" class="mb-1 mb-sm-0 mr-0 mr-sm-1" type="reset" size="sm" :block="$store.getters['app/currentBreakPoint'] === 'xs'">
                Batal
              </b-button>
            </b-col>

            <b-col sm="8">
              <!-- Parameter TABLE -->
              <b-card no-body class="border mt-1">
                <b-card-header class="p-1">
                  <b-card-title class="font-medium-1">
                    <feather-icon icon="GlobeIcon" size="14" />
                    <span class="align-middle ml-50">Kabupaten</span>
                  </b-card-title>
                </b-card-header>

                <b-row class="pl-1 pr-1 mt-1">
                  <b-col cols="3">
                    <b-form-group label-cols="4" label-cols-lg="5" label-size="sm" label="Region" label-for="input-sm">
                       <b-form-select v-model="region_filter" :options="region_filter_opt" size="sm" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="3">
                    <b-form-group label-cols="4" label-cols-lg="6" label-size="sm" label="Provinsi" label-for="input-sm">
                      <b-form-select v-model="propinsi_filter" :options="propinsi_filter_opt" size="sm" />
                    </b-form-group>
                  </b-col>

                  <!-- table -->

                  <!-- search input -->
                  <div class="custom-search d-flex justify-content-end">
                    <b-form-group>
                      <div class="d-flex align-items-center">
                        <label class="mr-1">Search</label>
                        <b-form-input v-model="searchTerm" placeholder="Search" type="text" class="d-inline-block" size="sm" />
                      </div>
                    </b-form-group>
                  </div>
                </b-row>

                <!-- table -->
                <vue-good-table
                  :columns="columns"
                  :rows="rows"
                  :rtl="direction"
                  :search-options="{
                    enabled: true,
                    externalQuery: searchTerm,
                  }"
                 
                  :pagination-options="{
                    enabled: true,
                    perPage: pageLength,
                  }"
                >
                  <template slot="table-row" slot-scope="props">

                    <!-- Column: Action -->
                    <span v-if="props.column.field === 'action'">
                      <span>
                        <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                          <template v-slot:button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                          </template>
                          <b-dropdown-item @click="onActionEdit(props.row.path)"> 
                            <feather-icon icon="Edit2Icon" class="mr-50" />
                            <span>Edit</span>
                          </b-dropdown-item>
                          <b-dropdown-item @click="onActionDelete(props.row.path)">
                            <feather-icon icon="TrashIcon" class="mr-50" />
                            <span>Hapus</span>
                          </b-dropdown-item>
                        </b-dropdown>
                      </span>
                    </span>

                    <!-- Column: Common -->
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                  </template>

                  <!-- pagination -->
                  <template slot="pagination-bottom" slot-scope="props">
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap ">
                          Showing 1 to
                        </span>
                        <b-form-select v-model="pageLength" :options="['3', '5', '10']" class="mx-1" @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                        <span class="text-nowrap"> of {{ props.total }} entries </span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="props.total"
                          :per-page="pageLength"
                          first-number
                          last-number
                          align="right"
                          prev-class="prev-item"
                          next-class="next-item"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        >
                          <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                          </template>
                          <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                          </template>
                        </b-pagination>
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-card>
              <!-- Action Buttons -->
            </b-col>
          </b-row>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { BCard, VBTooltip, BCardHeader, BContainer, BRow, BCol, BFormSelect, BForm, BButton, BButtonGroup, BFormGroup, BCollapse, BFormDatepicker, BFormInput, BAvatar, BBadge, BPagination, BDropdown, BDropdownItem } from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { TrashIcon, Edit2Icon } from "vue-feather-icons";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";
import axios from 'axios'
import metadata from "@/api/metadata";
import helper from '@/api/helper'

export default {
  components: {
    BCardCode,
    BCard,
    BCardHeader,
    BContainer,
    BRow,
    BCol,
    BFormSelect,
    BForm,
    BButton,
    BButtonGroup,
    BFormGroup,
    BCollapse,
    BFormDatepicker,
    BFormInput,
    TrashIcon,
    Edit2Icon,
    VueGoodTable,
    BAvatar,
    BBadge,
    BDropdown,
    BPagination,
    BDropdownItem,
  },
  data: function() {
    return {
      KOTA_SELECTED:"",
      pageLength: 3,
      dir: false,
      columns: [
        {
          label: "ID",
          field: "id",
        },
        {
          label: "Balai",
          field: "balai",
        },
        {
          label: "Propinsi",
          field: "propinsi",
        },
        {
          label: "Kabupaten",
          field: "kabupaten",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      rows: [],
      searchTerm: "",
    
      regions: "",
      regions_opt: [{ value: "", text: "Pilih Balai" }],
      region_filter: "",
      region_filter_opt: [{ value: "", text: "Pilih Balai" },{ value: "all", text: "Semua" }],
      propinsi: "",
      propinsi_opt: [{ value: "", text: "Pilih Propinsi" }],
      propinsi_filter: "",
      propinsi_filter_opt: [{ value: "", text: "Pilih Propinsi" },{ value: "all", text: "Semua" }],
      arr_data_propinsi : {},
      status_entry : "insert",
      arr_region :{},
      arr_data_kabupaten :{},
      kabupaten:"",
      path : "",
      update_path : "",
    };
  },
  computed: {
    
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  watch: {
      kabupaten(val){
        console.log("Kab : ",val)
      },
      regions(val) {
        console.log(" new : " + val);
        // this.listPropinsi(newValue)
        this.region_filter = val;
        if(this.propinsi != ""){
          this.getPath()
        }
      },
      propinsi(){
         if(this.regions != ""){
          this.getPath()
        }
      },
      region_filter(value) {
        this.KOTA_SELECTED=value
        if(value == "all"){
          this.listAllPropinsi();
          if(this.propinsi_filter == "all"){
            this.listAllKabupaten()
          }else if(this.propinsi_filter != ""){
             this.listKabupaten()
          }
        }else if(value != ""){
          this.listPropinsi(value);
          if(this.propinsi_filter == "all"){
            this.listKabupatenByRegion()
          }else if(this.propinsi_filter != ""){
             this.listKabupaten()
          }
        }
      },
      propinsi_filter(val){
        if(val == "all"){
          if(this.region_filter == "all"){
            this.listAllKabupaten()
          }else if(this.region_filter != ""){
            this.listKabupatenByRegion()
          }
        }else if(val != ""){
          this.listKabupaten()
        }
      }
    },
  created() {
    // this.$http.get("/good-table/basic").then((res) => {
    //   this.rows = res.data;
    // });
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  mounted(){
    this.optionRegion()
  },
  methods : {
    async getPath(){     
      this.path = "/"+this.regions+"/"+this.propinsi   
    },
    onActionEdit(val) {
      this.status_entry = "edit"
      const data = this.arr_data_kabupaten[val]
      // console.log("path : ",val)
      // console.log("arr : ",this.arr_data_kabupaten)
      // console.log("valna : ",data)
      this.regions = data.balai_id
      this.propinsi = data.propinsi_id
      this.kabupaten = data.kabupaten
      const path = val
      this.update_path = path



    },
    async onActionDelete(val) {
      console.log(val);
      // const res = await  axios.delete(helper.getBaseUrl()+val,helper.getConfig())
      var config = {
        method: 'delete',
        url: helper.getBaseUrl()+val,
        headers: helper.getHeader(),
        data : {"is_delete":1}
      };
      this.$swal({
          title: "Apakah Anda Yakin?",
          text: "Delete Provinsi??",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "DELETE",
          allowOutsideClick: false,
          allowEscapeKey: false,
          customClass: {
            confirmButton: "btn btn-danger",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            // axios.delete(helper.getBaseUrl()+val,helper.getConfig(),{"is_delete":1})
            axios(config)
            .then((response) => {
            this.$swal({
                title: "Hapus Sukses",
                text: "Kota/Kabupaten Telah di Hapus",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.listKabupaten(this.KOTA_SELECTED);
    
            })
            .catch((error) => {
              this.$swal({
                  title: "Hapus Error",
                  text: "Terjadi Kesalahan",
                  icon: "error",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
            });
          } else if (result.dismiss === "cancel") {
            this.showLoading = false;
            return;
          }
         
        });
      // try{
      //   const res = await metadata.deletePropinsi(val)
      //   this.region_filter = val.substr(1,1)
      // }catch(e){

      // }
    },
    update() {
       console.log("reg : ",this.arr_data_propinsi)
      // return
      const bodyData = {
        "@type": "Kabupaten",
        propinsi_name: this.arr_data_propinsi[this.path].propinsi_name,
        propinsi_code: this.arr_data_propinsi[this.path].propinsi_code,
        region_id: this.regions,
        propinsi_id: this.propinsi,
        region_description : this.arr_data_propinsi[this.path].balai,
        kabupaten_name : this.kabupaten
      };
      console.log(bodyData)
      console.log(this.path)
      if (this.regions == "" || this.propinsi == "" || this.kabupaten == "") {
        this.$swal({
          title: "Update Error!",
          text: "Silahkan Lengkapi data",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        metadata
          .patchKabupaten(bodyData, this.update_path)
          .then((response) => {
            if (response.status == 201 || response.status == 200 || response.status == 204) {
              this.$swal({
                title: "Update Sukses",
                text: "Kabupaten Telah di Update",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.propinsi_name = "";
              this.propinsi_code = "";
              this.listPropinsi(this.regions);
            }
          })
          .catch((error) => {
            this.$swal({
              title: "Input Error",
              text: "Terjadi Kesalahan",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
      }
      this.status_entry = "insert"
    },
    submit() {
       console.log("reg : ",this.arr_data_propinsi)
      // return
      const bodyData = {
        "@type": "Kabupaten",
        propinsi_name: this.arr_data_propinsi[this.path].propinsi_name,
        propinsi_code: this.arr_data_propinsi[this.path].propinsi_code,
        region_id: this.regions,
        propinsi_id: this.propinsi,
        region_description : this.arr_data_propinsi[this.path].balai,
        kabupaten_name : this.kabupaten
      };
      console.log(bodyData)
      console.log(this.path)
      if (this.regions == "" || this.propinsi == "" || this.kabupaten == "") {
        this.$swal({
          title: "Input Error!",
          text: "Silahkan Lengkapi data",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        metadata
          .postKabupaten(bodyData, this.path)
          .then((response) => {
            if (response.status == 201 || response.status == 200 || response.status == 204) {
              this.$swal({
                title: "Input Sukses",
                text: "Kabupaten Telah di simpan",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.propinsi_name = "";
              this.propinsi_code = "";
              this.listPropinsi(this.regions);
            }
          })
          .catch((error) => {
            this.$swal({
              title: "Input Error",
              text: "Terjadi Kesalahan",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
      }
      this.status_entry = "insert"
    },
    async optionRegion() {
      try {
        const { data } = await metadata.getRegionList();
        console.log(data);
        for (var i = 0; i < data.length; i++) {
          const dataRegion = {
            value: data[i].region_id,
            text: data[i].region_description,
          };
          this.regions_opt.push(dataRegion);
          this.region_filter_opt.push(dataRegion);
          if(this.arr_region[data[i].region_id] == undefined){
            this.arr_region[data[i].region_id] == ""
          }
          this.arr_region[data[i].region_id] = data[i].region_description
        }
      } catch (e) {
        console.log(e);
      }
    },
    async listPropinsi(region_id) {
      this.propinsi_opt = [{ value: "", text: "Pilih Propinsi" }]
      this.propinsi_filter_opt=[{ value: "", text: "Pilih Propinsi" },{ value: "all", text: "Semua" }]
      try {
        const { data } = await metadata.getPropinsiListByRegion(region_id);
        console.log(data);
        for (var i = 0; i < data.length; i++) {
          const rowsPropinsi = {
            value: data[i].propinsi_id,
            text: data[i].propinsi_name
          }
          this.propinsi_opt.push(rowsPropinsi)
          this.propinsi_filter_opt.push(rowsPropinsi)
          if(this.arr_data_propinsi[data[i].path] == undefined){
            this.arr_data_propinsi[data[i].path] = ""
          }
          const dataPropisi = {
            "id": data[i].propinsi_id,
            "balai": data[i].region_description,
            "propinsi_code": data[i].propinsi_code,
            "propinsi_name": data[i].propinsi_name,
            "path" : data[i].path,
            "balai_id" : data[i].region_id
          }
          this.arr_data_propinsi[data[i].path] = dataPropisi
         
          // this.items.push(dataPropinsi);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async listAllPropinsi() {
      this.propinsi_opt = [{ value: "", text: "Pilih Propinsi" }]
      this.propinsi_filter_opt=[{ value: "", text: "Pilih Propinsi" },{ value: "all", text: "Semua" }]
      try {
        const { data } = await metadata.getPropinsiList();
        console.log(data);
        for (var i = 0; i < data.length; i++) {
          const rowsPropinsi = {
            value: data[i].propinsi_id,
            text: data[i].propinsi_name
          }
          this.propinsi_opt.push(rowsPropinsi)
          this.propinsi_filter_opt.push(rowsPropinsi)
          if(this.arr_data_propinsi[data[i].path] == undefined){
            this.arr_data_propinsi[data[i].path] = ""
          }
          const dataPropisi = {
            "id": data[i].propinsi_id,
            "balai": data[i].region_description,
            "propinsi_code": data[i].propinsi_code,
            "propinsi_name": data[i].propinsi_name,
            "path" : data[i].path,
            "balai_id" : data[i].region_id
          }
          this.arr_data_propinsi[data[i].path] = dataPropisi
         
          // this.items.push(dataPropinsi);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async listAllKabupaten(){
      this.rows = []
      try {
        const { data } = await metadata.getKabupatenList();
        console.log(data);
        for (var i = 0; i < data.length; i++) {
           const rowsKabupaten = {
            id: data[i].kabupaten_id,
            balai: data[i].region_description,
            propinsi: data[i].propinsi_name,
            kabupaten : data[i].kabupaten_name,
            path : data[i].path,
            balai_id : data[i].region_id,
            propinsi_id : data[i].propinsi_id,
            propinsi_id_code : data[i].propinsi_code,
          }
          this.rows.push(rowsKabupaten)
          if(this.arr_data_kabupaten[data[i].path] == undefined){
            this.arr_data_kabupaten[data[i].path] = ""
          }
          const dataKabupaten = {
            "id": data[i].kabupaten_id,
            "balai": data[i].region_description,
            "propinsi": data[i].propinsi_name,
            "kabupaten" : data[i].kabupaten_name,
            "path" : data[i].path,
            "balai_id" : data[i].region_id,
            "propinsi_id" : data[i].propinsi_id,
            "propinsi_code" : data[i].propinsi_code,
          }
          this.arr_data_kabupaten[data[i].path] = dataKabupaten
         
          // this.items.push(dataPropinsi);
        }
      } catch (e) {
        console.log(e);
      }

    },
    async listKabupaten(){
      this.rows = []
      try {
        const { data } = await metadata.getKabupatenListByPropinsi(this.propinsi_filter)
        console.log(data);
        for (var i = 0; i < data.length; i++) {
           const rowsKabupaten = {
            id: data[i].kabupaten_id,
            balai: data[i].region_description,
            propinsi: data[i].propinsi_name,
            kabupaten : data[i].kabupaten_name,
            path : data[i].path,
            balai_id : data[i].region_id,
            propinsi_id : data[i].propinsi_id,
            propinsi_id_code : data[i].propinsi_code,
          }
          this.rows.push(rowsKabupaten)
          if(this.arr_data_kabupaten[data[i].path] == undefined){
            this.arr_data_kabupaten[data[i].path] = ""
          }
          const dataKabupaten = {
            "id": data[i].kabupaten_id,
            "balai": data[i].region_description,
            "propinsi": data[i].propinsi_name,
            "kabupaten" : data[i].kabupaten_name,
            "path" : data[i].path,
            "balai_id" : data[i].region_id,
            "propinsi_id" : data[i].propinsi_id,
            "propinsi_id_code" : data[i].propinsi_code,
          }
          this.arr_data_kabupaten[data[i].path] = dataKabupaten
         
          // this.items.push(dataPropinsi);
        }
      } catch (e) {
        console.log(e);
      }

    },
    async listKabupatenByRegion(){
      this.rows = []
      try {
        const { data } = await metadata.getKabupatenListByRegion(this.region_filter)
        console.log(data);
        for (var i = 0; i < data.length; i++) {
           const rowsKabupaten = {
            id: data[i].kabupaten_id,
            balai: data[i].region_description,
            propinsi: data[i].propinsi_name,
            kabupaten : data[i].kabupaten_name,
            path : data[i].path,
            balai_id : data[i].region_id,
            propinsi_id : data[i].propinsi_id,
            propinsi_id_code : data[i].propinsi_code,
          }
          this.rows.push(rowsKabupaten)
          if(this.arr_data_kabupaten[data[i].path] == undefined){
            this.arr_data_kabupaten[data[i].path] = ""
          }
          const dataKabupaten = {
            "id": data[i].kabupaten_id,
            "balai": data[i].region_description,
            "propinsi": data[i].propinsi_name,
            "kabupaten" : data[i].kabupaten_name,
            "path" : data[i].path,
            "balai_id" : data[i].region_id,
            "propinsi_id" : data[i].propinsi_id,
            "propinsi_id_code" : data[i].propinsi_code,
          }
          this.arr_data_kabupaten[data[i].path] = dataKabupaten
         
          // this.items.push(dataPropinsi);
        }
      } catch (e) {
        console.log(e);
      }

    },
  },
  
  
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
